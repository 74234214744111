import {Button, message, Space} from 'antd';
import {useEffect, useState} from 'react';
import useStrapiRest from '../../hooks/useStrapiRest';
import _pageConfig from './_pageConfig';
import {AuthLayout} from '../../components/layouts/AuthLayout';
import moment from 'moment';
import CONFIG from '../../config';
import {isMobileAndTabletCheck, removeMilliseconds} from '../../helper';
import styles from './_page.module.scss';
import {TableRecords} from '../../components/TableRecords';
import {useHistory} from 'react-router-dom';
import {getAxios} from '../../services/api';

const endpoint = _pageConfig.endpoint;

const dateFormat = 'DD/MM/YYYY';

export default function DashboardPage() {
  const [actions, data] = useStrapiRest('records');
  const [cronTabActions, cronTabData] = useStrapiRest('cronJob');
  const [today, setToday] = useState(null);
  const history = useHistory();

  async function loadThisMonth() {
    const currentUserId = JSON.parse(localStorage.getItem('user')).id;

    const params = {
      sort: 'createdAt:desc',
      populate: ['user'],
      filters: {
        user: {
          id: {
            $eq: currentUserId,
          },
        },
        date: {
          $gte: moment()
              .startOf('month')
              .format(CONFIG.apiDateFormat)
              .valueOf(),
          $lte: moment().endOf('month').format(CONFIG.apiDateFormat).valueOf(),
        },
      },
      pagination: {
        page: 1,
        pageSize: 1000,
      },
    };

    await actions.getItems(params);
  }

  useEffect(() => {
    const ref = setInterval(async () => {
      message.success('Auto reload data each 30 seconds');
      await loadThisMonth();
    }, 30000);

    return function() {
      clearInterval(ref);
    };
  }, []);

  useEffect(() => {
    (async () => {
      await loadThisMonth();
    })();
  }, []);

  useEffect(() => {
    if (data && data.list) {
      for (let i = 0; i < data.list.length; i++) {
        if (data.list[i].date ===
            moment().format(CONFIG.apiDateFormat).valueOf()) {
          setToday(data.list[i]);
          return;
        }
      }
    }

    // Default
    setToday(undefined);
  }, [data]);

  async function handleClickCheckIn() {
    await cronTabActions.customGet('manualCheckInGuide');
    alert('Vui lòng kiểm tra tin nhắn từ My MOHA trên Slack và làm theo hướng dẫn!');
    // // alert('handleClickCheckIn');
    // const currentUserId = JSON.parse(localStorage.getItem('user')).id;
    // const currentDate = moment().format(CONFIG.apiDateFormat).valueOf();
    // const currentTime = moment().format(CONFIG.apiTimeFormat).valueOf();
    // try {
    //   await actions.createItem({
    //     date: currentDate,
    //     checkInAt: currentTime,
    //     checkOutAt: currentTime,
    //     checkInType: 'MANUAL',
    //     checkOutType: 'MANUAL',
    //     user: {
    //       connect: [currentUserId],
    //     },
    //   });
    //   await loadThisMonth();
    //
    //   message.success('Check in successfully');
    //
    // } catch (e) {
    //   console.error(e);
    //   message.error('There is error while checking in');
    // }
  }

  async function handleClickCheckOut() {
    try {
      const latestTodayResponse = await actions.getItem(today.id);
      console.log('DEBUG latestToday', latestTodayResponse);

      const todayRecord = latestTodayResponse.detail;
      const currentTime = moment().format(CONFIG.apiTimeFormat).valueOf();

      await actions.updateItem(todayRecord.id, {
        ...todayRecord,
        checkOutAt: currentTime,
        checkOutType: 'MANUAL',
      });

      await loadThisMonth();
      message.success('Saved checkpoint successfully');
    } catch (e) {
      console.error(e);
      message.error('There is error while checking out');
    }
  }


  async function handleTurnOnServer() {
    const currentUserEmail = JSON.parse(localStorage.getItem('user')).email;

    try {
      await getAxios().put('/api/pve', {
        data: {
          isAskingTurnOn: true,
          askingTurnOnBy: currentUserEmail
        }
      });

      message.success('The server received your request. Please wait for a while.');
    } catch (e) {
      console.error(e)
      message.error('There is error while turning on server');
    }
  }

  return (
      <AuthLayout pageTitle={'Dashboard'}>
        <div className={styles.moduleWrapper}>
          <div className={'pageWrapper'}>
            <div className="pageContent">
              <Space align={'center'} className={'ctaActions'}>
                <Button
                    type={'default'}
                    onClick={handleClickCheckIn}
                    disabled={today === null || (today && today.checkInAt)}
                >
                  <div className="btn">
                    {today?.checkInAt ?
                        `You checked in at ${removeMilliseconds(
                            today?.checkInAt)}` :
                        'Check in now'}
                  </div>
                </Button>
                <Button
                    type={'default'}
                    onClick={handleClickCheckOut}
                    disabled={!today || !today.checkInAt}>
                  <div className="btn">
                    {`Check out`}
                    <br/>
                    <span style={{fontSize: '12px'}}>{(today &&
                        today.checkOutAt) ?
                        `(Last checkpoint at ${removeMilliseconds(
                            today.checkOutAt)})` :
                        ''}</span>
                  </div>
                </Button>
                <Button>
                  <div className={'btn'}
                       onClick={() => {
                         alert(
                             'Hãy đảm bảo rằng bạn đang kết nối tới WIFI công ty. Vui lòng liên hệ Huệ nếu gặp lỗi khi đăng ký.');
                         if (isMobileAndTabletCheck()) {
                           history.push(`/devices/guideline`);
                         } else {
                           history.push(`/devices/mac`);
                         }
                       }}
                  >Register wifi check in for this device
                  </div>
                </Button>
                <Button
                    onClick={handleTurnOnServer}
                >
                  <div className={'btn'}>Bật server công ty
                  </div>
                </Button>
              </Space>

              <div className="mobileOnly">
                <center>
                  <div className="ctaActions">
                    <br/>

                    <Button>
                      <div className={'btn'}
                           onClick={() => {
                             localStorage.removeItem('user');
                             localStorage.removeItem('token');
                             history.push(`/auth/init`);
                           }}
                      >Logout
                      </div>
                    </Button>
                  </div>
                </center>
              </div>

              <div className={'pcOnly'}>
                <br/>
                <center><h4>Your working histories of current month</h4>
                </center>
                <TableRecords data={data} isShowAbsent={true} dateFrom={moment().startOf('month').format(CONFIG.apiDateFormat).valueOf()} dateTo={moment().endOf('month').format(CONFIG.apiDateFormat).valueOf()}/>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
  );
}
