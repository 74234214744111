import styles from './_page.module.scss';
import _ from 'lodash';
import {Fragment, useEffect, useState} from 'react';
import useStrapiRest from '../../hooks/useStrapiRest';
import _pageConfig from './_pageConfig';
import {AuthLayout} from '../../components/layouts/AuthLayout';
import moment from 'moment';

const endpoint = _pageConfig.endpoint;

function checkIfContainsToday(startTime, endTime) {
  if (!startTime || !endTime) {
    return false;
  }

  const currentDate = moment().startOf('day'); // Get the current date at midnight

  const startDate = moment(startTime);
  const endDate = moment(endTime);

  // Create a moment object for the start date and increment day by day
  let currentDateToCheck = startDate.clone();

  while (currentDateToCheck.isSameOrBefore(endDate)) {
    if (currentDateToCheck.isSame(currentDate, 'day')) {
      return true;
    }

    currentDateToCheck.add(1, 'day'); // Increment by one day
  }

  return false;
}



export default function LeavesPage() {
  const [actions, data] = useStrapiRest(endpoint);

  useEffect(() => {
    (async () => {
      await actions.getItems();
    })();
  }, []);


  return (
      <AuthLayout pageTitle={'Leave records'}>
        <div className={'pageWrapper'}>
          <div className={'pageTitle'}></div>
          <div className={'pageContent'}>
            <div className={styles.moduleWrapper}>
              <div className="commonTable">
                <div className="tableTopActions">
                  <div className="title">Leave records of current month (get from Man system)</div>
                </div>

                {(!data?.list?.records || !data?.list?.records.length) && (
                    <div className="noContent">
                      Không tìm thấy dữ liệu. Vui lòng thay đổi điều kiện tìm
                      kiếm.
                    </div>
                )}

                {!!data?.list?.records && !!data?.list?.records.length && (
                    <Fragment>
                      <table className={'tableContent'}>
                        <thead>
                        <tr>
                          <th className={'no'}>STT</th>
                          <th>Email</th>
                          <th>Start time</th>
                          <th>End time</th>
                          <th>Leaving days</th>
                          <th>Type</th>
                          <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {_.sortBy(data?.list?.records, ['startTime']).reverse()
                            .map((item, index) => {
                              return (
                                  <tr key={item.id} className={(checkIfContainsToday(item.startTime, item.endTime)) ? 'todayRecord' : ''}>
                                    <td className={'no'}>
                                      {index + 1}
                                    </td>
                                    <td>
                                      {item.email.split('@')[0]}
                                    </td>
                                    <td>
                                      {item.startTime}
                                    </td>
                                    <td>
                                      {item.endTime}
                                    </td>
                                    <td>
                                      {item.leavingDays}
                                    </td>
                                    <td>
                                      {item.type}
                                    </td>
                                    <td>
                                      {item.status}
                                    </td>
                                  </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
  );
}
