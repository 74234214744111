import {useEffect} from 'react';
import useStrapiRest from '../../hooks/useStrapiRest';

export default function MacDetectionPage() {
  const [optionActions, optionData] = useStrapiRest('public-config');

  useEffect(() => {
    (async () => {
      await optionActions.getItems();
    })();
  }, []);

  useEffect(() => {
    if (optionData && optionData.list) {
      (async () => {
        try {
          window.location.href = `${optionData.list.macAddressServer}?callback=${window.location.origin}`;
        } catch (e) {
          console.error(e);
          alert(
              'Hãy đảm bảo bạn đã kết nối tới wifi công ty. Hoặc liên hệ Huệ để được hỗ trợ!');
        }
      })();
    }
  }, [optionData]);

  return (
      <center>
        <br/><br/><br/>
        <div style={{padding: '20px 20px'}}>Hệ thống đang kiểm tra MAC Address của bạn.
          <br/>
          Nếu hệ thống dừng lại ở màn hình này quá 15s thì có vẻ như bạn chưa kết nối
          vào Wifi công ty. Vui lòng kiểm tra lại hoặc liên hệ Huệ để được hỗ trợ.
        </div>
      </center>
  );
}
