import {useEffect} from 'react';
import useStrapiRest from '../../hooks/useStrapiRest';
import VideoPlayer from '../../components/VideoPlayer';
import {Link} from 'react-router-dom';
import {Button} from 'antd';

const guidelineVideo = require('../../assets/videos/guideline.mp4');

export default function MacGuidelinePage() {
  return (
      <div style={{padding: '0 50px'}}>
        <center>
          <h4>Hãy đảm bảo bạn đã kết nối tới Wifi MOHA for Mobile và tắt Private IP theo hướng dẫn sau</h4>

          <VideoPlayer
              options={{
                playsinline: true,
                autoplay: true,
                height: '500px',
                controls: true,
                responsive: true,
                fluid: false,
                sources: [
                  {
                    src: guidelineVideo,
                    type: 'video/mp4',
                  },
                ],
              }}
          />

          <br/>

          <Link to={'/devices/mac'}>
            <Button type={'primary'}>Tôi đã thực hiện theo hướng dẫn trên</Button>
          </Link>

          <br/><br/><br/><br/><br/>
        </center>
      </div>
  );
}
