import styles from './_page.module.scss';
import {Button, Form, Input, message} from 'antd';
import {useHistory} from 'react-router-dom';
import {useEffect} from 'react';
import useStrapiRest from '../../hooks/useStrapiRest';
import _pageConfig from './_pageConfig';
import {AuthLayout} from '../../components/layouts/AuthLayout';

const endpoint = _pageConfig.endpoint;
export default function ProfileUpdatePage() {
  const [actions, data] = useStrapiRest(endpoint);

  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    (async () => {
      const currentUserId = JSON.parse(localStorage.getItem('user')).id;
      await actions.getItem(currentUserId);
    })();
  }, []);

  useEffect(() => {
    console.log('DEBUG data', data);
  }, [data?.detail]);

  useEffect(() => {
    if (data.detail) {
      form.setFieldsValue({
        ...data.detail,
      });
    }
  }, [JSON.stringify(data?.detail)]);

  async function onFinish(values) {
    console.log('DEBUG values', values);
    try {
      const currentUserId = JSON.parse(localStorage.getItem('user')).id;
      await actions.updateItem(currentUserId, values, true);
      message.success('Updated your profile successfully!');
    } catch (e) {
      console.error(e);
      message.warning('Error while updating your profile!');
    }
  }

  return (
      <AuthLayout pageTitle={'Update my profile'}>
        <div className={'pageWrapper'}>
          <div className={'pageContent'}>
            <div className={styles.moduleWrapper}>
              <Form
                  form={form}
                  onFinish={onFinish}
                  colon={false}
                  layout="vertical"
                  style={{
                    width: '100%',
                  }}
                  className={'oneColumns'}
              >
                <Form.Item
                    name={'email'}
                    label={'Your email (readonly)'}
                >
                  <Input readOnly/>
                </Form.Item>
                <Form.Item
                    name={'fullName'}
                    label={'Your full name'}
                    rules={[
                      {required: true},
                      {min: 3},
                      {max: 30},
                    ]}
                >
                  <Input/>
                </Form.Item>

                <Form.Item className={'submitRow'}>
                  <Button block type="primary" htmlType="submit">Update my profile</Button>
                </Form.Item>
              </Form>
            </div>

            <div className="mobileOnly">
              <Button block>
                <div className={'btn'}
                     onClick={() => {
                       history.push(`/`);
                     }}
                >Back to HOME
                </div>
              </Button>
            </div>
          </div>
        </div>
      </AuthLayout>
  );
}
