import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {Form, Input, Button, message} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import CONFIG from '../../config';
import useStrapiRest from '../../hooks/useStrapiRest';
import _pageConfig from '../Blogs/_pageConfig';

export default function AuthPage() {
  const [actions, data] = useStrapiRest('auth/local');

  const onFinish = async (values) => {
    // You can handle form submission here, e.g., sending a POST request to your server with the login data.
    console.log('Received values:', values);
    try {
      const res = await actions.customPost('', {
        identifier: values.email,
        password: values.password,
      });
      message.success('Đăng nhập thành công!');
      console.log('DEBUG response', res);
      localStorage.setItem('token', res.jwt);
      localStorage.setItem('user', JSON.stringify(res.user));
      setTimeout(() => {
        const redirect = localStorage.getItem('redirectUrl');
        if (redirect) {
          localStorage.removeItem('redirectUrl');
        }
        window.location.href = redirect ? redirect : '/';
      }, 500); // Redirect to homepage after 3 sec
    } catch (e) {
      message.error('Sai email hoặc mật khẩu!');
    }
  };

  useEffect(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }, []);

  return (
      <div>
        <Helmet>
          <title>My MOHA</title>
        </Helmet>

        <center>
          <br /><br /><br />
          <Button
              style={{ minWidth: '200px', maxWidth: '100%' }}
              onClick={() =>
                  (window.location.href = `${CONFIG.apiEndpoint}/api/connect/google?callback=${window.location.origin}/auth/callback`)
              }
              type="primary"
          >
            Click để login bằng TK google @mohasoftware.com
          </Button>
          <br /><br /><br />
          <p>Nếu bạn không có email @mohasoftware.com, vui lòng login bằng email/password được công ty cung cấp!</p>

          <div style={{maxWidth: '300px', textAlign: 'left'}}>
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
              <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: 'Please input your email!' },
                    { type: 'email', message: 'Please enter a valid email address!' },
                  ]}
                  style={{
                    marginBottom: '10px'
                  }}
              >
                <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                />
              </Form.Item>
              <Form.Item
                  name="password"
                  rules={[{ required: true, message: 'Please input your Password!' }]}
                  style={{
                    marginBottom: '20px'
                  }}
              >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                />
              </Form.Item>
              <Form.Item>
                <Button
                    block
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                >
                  Đăng nhập
                </Button>
              </Form.Item>
            </Form>
          </div>
        </center>
      </div>
  );
}
