import styles from './_page.module.scss';
import {Button} from 'antd';
import {Fragment, useEffect} from 'react';
import useStrapiRest from '../../hooks/useStrapiRest';
import _pageConfig from './_pageConfig';
import {AuthLayout} from '../../components/layouts/AuthLayout';
import {formatDateTimeToLocal} from '../../helper';
import {Link} from 'react-router-dom';

const endpoint = _pageConfig.endpoint;

export default function BlogsPage() {
  const [actions, data] = useStrapiRest(endpoint);

  useEffect(() => {
    (async () => {
      await actions.getItems({
        // sort: 'created_at:asc',
        populate: '*',
      });
    })();
  }, []);

  useEffect(() => {
    document.body.setAttribute('type', 'blog');
    return function() {
      document.body.setAttribute('type', '');
    }
  }, []);

  return (
      <AuthLayout pageTitle={'MOHA BEAT'}>
        <div className={'pageWrapper'}>
          <div className={'pageTitle'}></div>
          <div className={'pageContent'}>
            <div className={styles.moduleWrapper}>
              <div className="commonTable">
                <div className="tableTopActions">
                  <div className="title">Các số đã ra</div>
                </div>

                {(!data.list || !data.list.length) && (
                    <div className="noContent">
                      Không tìm thấy dữ liệu. Vui lòng thay đổi điều kiện tìm
                      kiếm.
                    </div>
                )}

                {!!data.list && !!data.list.length && (
                    <Fragment>
                      <table className={'tableContent'}>
                        <thead>
                        <tr>
                          <th className={'no'}>STT</th>
                          <th>Tiêu đề</th>
                          <th>Thời gian phát hành</th>
                          <th>Thao tác</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data?.list?.map((item, index) => {
                          return (
                              <tr key={item.id}>
                                <td className={'no'}>
                                  {index + 1}
                                </td>
                                <td>
                                  {item.title}
                                </td>
                                <td>
                                  {formatDateTimeToLocal(item.created_at)}
                                </td>
                                <td>
                                  <Link to={`/blog/${item.id}`}>
                                    <Button type={'primary'}>Xem chi
                                      tiết</Button>
                                  </Link>
                                </td>
                              </tr>
                          );
                        })}
                        </tbody>
                      </table>
                    </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
  );
}
