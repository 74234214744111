import styles from './_page.module.scss';
import {Button, message, Popconfirm, Space} from 'antd';
import React, {Fragment, useEffect} from 'react';
import useStrapiRest from '../../hooks/useStrapiRest';
import _pageConfig from './_pageConfig';
import {AuthLayout} from '../../components/layouts/AuthLayout';
import CONFIG from '../../config';
import {
  formatDateTimeToLocal,
  isCheckInTodayRecord,
  renderDeviceStatus,
} from '../../helper';
import {Link} from 'react-router-dom';

const endpoint = _pageConfig.endpoint;

const dateFormat = CONFIG.localDateFormat;
const moment = require('moment-timezone');
moment.tz.setDefault('Asia/Ho_Chi_Minh');

export default function DevicesPage() {
  const [actions, data] = useStrapiRest(endpoint);
  const [wifiActions, wifiData] = useStrapiRest('wifi-device');

  useEffect(() => {
    (async () => {

      await wifiActions.getItems();

      const currentUserId = JSON.parse(localStorage.getItem('user')).id;
      await actions.getItems({
        sort: 'createdAt:asc',
        populate: ['user'],
        filters: {
          user: {
            id: {
              $eq: currentUserId,
            },
          },
        },
        pagination: {
          page: 1,
          pageSize: 1000,
        },
      });
    })();
  }, []);

  return (
      <AuthLayout pageTitle={'Check in devices'}>
        <div className={'pageWrapper'}>
          <div className={'pageTitle'}></div>
          <div className={'pageContent'}>
            <div className={styles.moduleWrapper}>
              <div className="commonTable">
                <div className="tableTopActions">
                  <div className="title">Your registered devices for Wifi check in</div>
                  <div className="right">
                    <Link to={`/${endpoint}/mac`}>
                      <Button type={'primary'}>Register current device</Button>
                    </Link>
                  </div>
                </div>

                {(!data.list || !data.list.length) && (
                    <div className="noDevices">
                      <div>You have not registered any devices!</div>
                      <br/>
                      <Link to={`/${endpoint}/mac/`}>
                        <Button block type={'primary'}>Register current device</Button>
                      </Link>
                    </div>
                )}

                {!!data.list && !!data.list.length && (
                    <Fragment>
                      <table className={'tableContent'}>
                        <thead>
                        <tr>
                          <th className={'no'}>No</th>
                          <th>Device name</th>
                          <th>MAC Address</th>
                          <th>Current status</th>
                          <th>Device status</th>
                          <th>Created at</th>
                          <th className={'actions'}>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data?.list?.map((item, index) => {
                          return (
                              <tr key={item.id}
                                  className={isCheckInTodayRecord(item) ?
                                      'todayRecord' :
                                      'notTodayRecord'}>
                                <td className={'no'}>
                                  {index + 1}
                                </td>
                                <td>
                                  {item.name}
                                </td>
                                <td>
                                  {item.macAddress}
                                </td>
                                <td>
                                  {renderDeviceStatus(
                                      wifiData?.list?.devices?.includes(
                                          item.macAddress))}
                                </td>
                                <td>{item.isDisabled ? 'Disabled' : 'Normal'}</td>
                                <td>
                                  {formatDateTimeToLocal(item.createdAt)}
                                </td>
                                <td className={'actions'}>
                                  <Space>
                                    <Popconfirm
                                        title="Please confirm!"
                                        description="Are sure that you want to do this action?"
                                        onConfirm={async () => {
                                          try {
                                            await actions.deleteItem(item.id);
                                            message.success(
                                                'Deleted successfully!');
                                            const currentUserId = JSON.parse(localStorage.getItem('user')).id;
                                            await actions.getItems({
                                              sort: 'createdAt:asc',
                                              populate: ['user'],
                                              filters: {
                                                user: {
                                                  id: {
                                                    $eq: currentUserId,
                                                  },
                                                },
                                              },
                                              pagination: {
                                                page: 1,
                                                pageSize: 1000,
                                              },
                                            });
                                          } catch (e) {
                                            message.warning(
                                                'Error while deleting!');
                                          }
                                        }}
                                    >
                                      <Button>Delete</Button>
                                    </Popconfirm>
                                  </Space>
                                </td>
                              </tr>
                          );
                        })}
                        </tbody>
                      </table>
                    </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
  );
}
