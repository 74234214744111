const CONFIG = {
  apiEndpoint: 'https://dev-api.checkin.moha.team',
  isoFormat: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  apiTimeFormat: 'HH:mm:ss.SSS',
  apiDateFormat: 'YYYY-MM-DD',
  localDateFormat: 'YYYY-MM-DD',
  localDateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
};

export default CONFIG;
