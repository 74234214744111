import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import CONFIG from '../../config';

export default function AuthCallbackPage() {
  const history = useHistory();

  useEffect(() => {
    // Successfully logged with the provider
    // Now logging with strapi by using the access_token (given by the provider) in props.location.search
    // eslint-disable-next-line no-restricted-globals
    fetch(`${CONFIG.apiEndpoint}/api/auth/google/callback${location.search}`)
        .then(res => {
          if (res.status !== 200) {
            throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
          }
          return res;
        })
        .then(res => res.json())
        .then(res => {
          // Successfully logged with Strapi
          // Now saving the jwt to use it for future authenticated requests to Strapi
          localStorage.setItem('token', res.jwt);
          localStorage.setItem('user', JSON.stringify(res.user));
          setTimeout(() => {
            const redirect = localStorage.getItem('redirectUrl');
            if (redirect) {
              localStorage.removeItem('redirectUrl');
            }
            window.location.href = redirect ? redirect : '/';
          }, 500); // Redirect to homepage after 3 sec
        })
        .catch(err => {
          console.log(err);
        });
    // eslint-disable-next-line no-restricted-globals
  }, [history, location.search]);

  return (
      <div>
        <center>
          <br/><br/>
          Authenticating user...
        </center>
      </div>
  );
}
