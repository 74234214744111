import styles from './_page.module.scss';
import {Form} from 'antd';
import {Fragment, useEffect, useState} from 'react';
import useStrapiRest from '../../hooks/useStrapiRest';
import _pageConfig from './_pageConfig';
import {AuthLayout} from '../../components/layouts/AuthLayout';
import {
  formatDateTimeToLocal,
  removeMilliseconds, renderCheckInOutType,
  renderDeviceStatus,
} from '../../helper';
import moment from 'moment';
import CONFIG from '../../config';
import {AiOutlineWarning} from 'react-icons/ai';
import {FaMobileAlt} from 'react-icons/fa';
import {IoMdLaptop} from 'react-icons/io';

const endpoint = _pageConfig.endpoint;

export default function UsersPage() {
  const [actions, data] = useStrapiRest(endpoint);
  const [wifiActions, wifiData] = useStrapiRest('wifi-device');
  const [recordActions, recordData] = useStrapiRest('records');
  const [form] = Form.useForm();
  const [mappingUserId, setMappingUserId] = useState({});
  const [activeUsers, setActiveUsers] = useState({});

  useEffect(() => {
    (async () => {
      await actions.getItems({
        filters: {
          blocked: {
            $eq: false
          },
          confirmed: {
            $eq: true
          }
        },
        sort: 'email:asc',
        populate: ['devices'],
      });

      await wifiActions.getItems();
      await loadTodayRecords();
    })();
  }, []);

  useEffect(() => {
    const mapping = {};
    if (recordData && recordData.list) {
      recordData.list.forEach(record => {
        mapping[record.user.id] = record;
      })
    }

    setMappingUserId(mapping);
  }, [recordData, data])

  async function loadTodayRecords() {
    const params = {
      sort: 'createdAt:asc',
      populate: ['user'],
      filters: {
        date: {
          $gte: moment()
              .startOf('day')
              .format(CONFIG.apiDateFormat)
              .valueOf(),
          $lte: moment().endOf('day').format(CONFIG.apiDateFormat).valueOf(),
        },
      },
      pagination: {
        page: 1,
        pageSize: 1000,
      },
    };

    await recordActions.getItems(params);
  }

  useEffect(() => {
    const ref = setInterval(async () => {
      await wifiActions.getItems();
    }, 10000);

    return function() {
      clearInterval(ref);
    };
  }, []);

  useEffect(() => {
    if (data && wifiData && data.list && wifiData.list) {
      const ids = {};
      const macs = wifiData.list.devices;
      data.list.forEach(user => {
        if (user.devices) {
          user.devices.forEach(device => {
            if (macs.includes(device.macAddress)) {
              ids[user.id] = true;
            }
          });
        }
      });

      setActiveUsers(ids);
    }
  }, [data, wifiData]);

  return (
      <AuthLayout pageTitle={'User devices'}>
        <div className={'pageWrapper'}>
          <div className={'pageTitle'}></div>
          <div className={'pageContent'}>
            <div className={styles.moduleWrapper}>
              <div className="commonTable">
                <div className="tableTopActions">
                  <div className="title">User devices</div>
                </div>

                {(!data.list || !data.list.length) && (
                    <div className="noContent">
                      Không tìm thấy dữ liệu. Vui lòng thay đổi điều kiện tìm
                      kiếm.
                    </div>
                )}

                {!!data.list && !!data.list.length && (
                    <Fragment>
                      <table className={'tableContent'}>
                        <thead>
                        <tr>
                          <th className={'no'}>STT</th>
                          <th>Full name</th>
                          <th>Email</th>
                          <th style={{width: '250px'}}>Mobile statuses</th>
                          <th style={{width: '250px'}}>Laptop statuses</th>
                          <th>Today check in</th>
                          <th>Last checkpoint</th>
                          <th>Slack ID</th>
                          <th>Created at</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data?.list?.map((item, index) => {
                          return (
                              <tr key={item.id}>
                                <td className={'no'}>
                                  {index + 1}
                                </td>
                                <td>
                                  {item.fullName}
                                </td>
                                <td>
                                  {item.email.split('@')[0]}
                                </td>
                                <td>
                                  {/*{JSON.stringify(wifiData)}*/}
                                  <div className="deviceStatuses">
                                    {item?.devices?.filter(item => !!item.isMobile).map(device => {
                                      return (
                                          <div className={'deviceStatus'}>
                                           <div className="deviceType">
                                           </div>{device.name} {renderDeviceStatus(wifiData.list?.devices.includes(device.macAddress))}
                                          </div>
                                      )
                                    })}
                                  </div>
                                  {/*{renderDeviceStatus(activeUsers[item.id])}*/}
                                </td>

                                <td>
                                  {/*{JSON.stringify(wifiData)}*/}
                                  <div className="deviceStatuses">
                                    {item?.devices?.filter(item => !item.isMobile).map(device => {
                                      return (
                                          <div className={'deviceStatus'}>
                                            <div className="deviceType">
                                            </div>{device.name} {renderDeviceStatus(wifiData.list?.devices.includes(device.macAddress))}
                                          </div>
                                      )
                                    })}
                                  </div>
                                  {/*{renderDeviceStatus(activeUsers[item.id])}*/}
                                </td>
                                <td>
                                  {!!mappingUserId[item.id] && (
                                      <div className="flexCenterLeft">
                                        <div>{removeMilliseconds(mappingUserId[item.id].checkInAt)}</div>
                                        <div>{renderCheckInOutType(mappingUserId[item.id].checkInType)}</div>
                                      </div>
                                  )}

                                  {!mappingUserId[item.id] && (
                                      <div className="flexCenterLeft">
                                        <div>N/A</div>
                                        <div><AiOutlineWarning color={'red'} size={20}/></div>
                                      </div>
                                  )}
                                </td>
                                <td>
                                  {!!mappingUserId[item.id] && mappingUserId[item.id].checkOutAt && (
                                      <div className="flexCenterLeft">
                                        <div>{removeMilliseconds(mappingUserId[item.id].checkOutAt)}</div>
                                        <div>{renderCheckInOutType(
                                            mappingUserId[item.id].checkOutType)}</div>
                                      </div>
                                  )}

                                  {(!mappingUserId[item.id] || !mappingUserId[item.id].checkOutAt) && (
                                      <div className="flexCenterLeft">
                                        <div>N/A</div>
                                        <div><AiOutlineWarning color={'red'} size={20}/></div>
                                      </div>
                                  )}
                                </td>
                                <td>{item.slackId}</td>
                                <td>
                                  {formatDateTimeToLocal(item.createdAt)}
                                </td>
                              </tr>
                          );
                        })}
                        </tbody>
                      </table>
                    </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
  );
}
