import styles from './_page.module.scss';
import {Button, Form, Input, message, Select} from 'antd';
import {Link, useHistory, useParams} from 'react-router-dom';
import {Fragment, useEffect, useState} from 'react';
import useStrapiRest from '../../hooks/useStrapiRest';
import _pageConfig from './_pageConfig';
import {AuthLayout} from '../../components/layouts/AuthLayout';
import _ from 'lodash';
import {formatDate} from '../../helper';
import {FcLike} from 'react-icons/fc';

export default function BlogsUpdatePage() {
  const [actions, data] = useStrapiRest(_pageConfig.endpoint);
  const [actionsOther, dataOther] = useStrapiRest(_pageConfig.endpoint);
  const params = useParams();
  const [id, setId] = useState();
  useEffect(() => {
    (async () => {
      setId(params.id);
      await actions.getItem(params.id, {
        populate: '*'
      });

      await actionsOther.getItems({
        filters: {
          id: {
            $ne: params.id
          }
        }
      });
    })();
  }, [params.id]);
  useEffect(() => {
    if (data.detail) {

    }
  }, [JSON.stringify(data?.detail)]);

  useEffect(() => {
    try {
      document.querySelector('.pageWrapper').scrollTo(0, 0);
    } catch (e) {}
  }, [params.id]);

  useEffect(() => {
    document.body.setAttribute('type', 'blog');
    return function() {
      document.body.setAttribute('type', '');
    }
  }, []);

  async function handleLike() {
    message.success('Thank you for your support!');
    const userId = JSON.parse(localStorage.getItem('user')).id;

    // Update like
    await actions.updateItem(params.id, {
      ...data?.detail,
      likedUsers: {
        connect: [userId]
      }
    });

    // Reload
    await actions.getItem(params.id, {
      populate: '*'
    });
  }

  return (
      <AuthLayout pageTitle={data?.detail?.title}>
        <div className={'pageWrapper'}>
          <div className={'pageContent'}>
            <div className={styles.moduleWrapper}>
              {/*<div className={'blogDate'}>Ngày phát hành: {formatDate(data?.detail?.created_at)}</div>*/}
              {
                _.sortBy(data?.detail?.files.map(item => {
                  return {
                    sorter: Number(item.name.split('.')[0]),
                    ...item
                  }
                }), 'sorter').map(image => {
                  return (
                      <div className={'image'}>
                        <img src={`https://dev-api.checkin.moha.team${image.url}`} alt="Image"/>
                      </div>
                  )
                })
              }

              <div className="image">
                <h2>Các số trước:</h2>
                {dataOther?.list?.map(item => {
                  return (
                      <Fragment>
                        <Link to={'/blog/' + item.id}>
                          {item.title}
                        </Link>
                        <br/>
                      </Fragment>
                  )
                })}
              </div>

              <div className="likeBtn" onClick={handleLike}>
                <FcLike size={30} color={'red'} />
                <div className="counter">{data?.detail?.likedUsers?.length || ''}</div>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
  );
}
