import './App.module.scss';

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';

import AuthPage from './pages/Auth/AuthPage';
import AuthCallbackPage from './pages/Auth/AuthCallbackPage';
import {useEffect} from 'react';
import {ConfigProvider, Spin} from 'antd';

import styles from './App.module.scss';
import ProfileUpdatePage from './pages/Profile/ProfileUpdatePage';
import RecordsPage from './pages/Records/RecordsPage';
import DashboardPage from './pages/Dashboard/DashboardPage';
import DevicesPage from './pages/Devices/DevicesPage';
import DeviceUpdatePage from './pages/Devices/DeviceUpdatePage';
import UsersPage from './pages/Users/UsersPage';
import MacDetectionPage from './pages/Devices/MacDetectionPage';
import MacGuidelinePage from './pages/Devices/MacGuidelinePage';
import LeavesPage from './pages/Leaves/LeavesPage';
import BlogsPage from './pages/Blogs/BlogsPage';
import BlogsUpdatePage from './pages/Blogs/BlogsUpdatePage';

document.addEventListener('keydown', e => {
  if (document.body.getAttribute('loading') === 'true') {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();
  }
});

function App() {
  const history = useHistory();
  useEffect(() => {
    console.log('DEBUG history', history);
  }, [history?.location]);

  return (
      <div className={styles.moduleWrapper}>
        <div className="loadingOverlay">
          <div className="content">
            <Spin size="large"/>
          </div>
        </div>

        <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorPrimary: '#000',
                  algorithm: true, // Enable algorithm
                },
                Checkbox: {
                  colorPrimary: '#000',
                  algorithm: true, // Enable algorithm
                },
              },
              // algorithm: theme.darkAlgorithm
            }}>
          <Router>
            <Switch>
              <Route path="/records">
                <RecordsPage/>
              </Route>

              <Route exact path={['/devices/edit/:id', '/devices/edit']}>
                <DeviceUpdatePage/>
              </Route>

              <Route exact path={['/devices/mac']}>
                <MacDetectionPage/>
              </Route>

              <Route exact path={['/devices/guideline']}>
                <MacGuidelinePage/>
              </Route>

              <Route path="/devices">
                <DevicesPage/>
              </Route>

              <Route path="/leaves">
                <LeavesPage/>
              </Route>


              <Route path="/users">
                <UsersPage/>
              </Route>


              <Route path="/blog/:id">
                <BlogsUpdatePage/>
              </Route>

              <Route path="/blog">
                <BlogsPage/>
              </Route>

              <Route path="/dashboard">
                <DashboardPage/>
              </Route>

              <Route exact path="/profile">
                <ProfileUpdatePage/>
              </Route>

              <Redirect exact from={'/'} to="/dashboard"/>
              <Redirect exact from={'/auth/logout'} to="/auth/init"/>

              <Route path="/auth/callback">
                <AuthCallbackPage/>
              </Route>

              <Redirect exact from={'/auth'} to="/auth/init"/>
              <Route path="/auth/init">
                <AuthPage/>
              </Route>

              <Redirect from={'*'} to="/"/>
            </Switch>
          </Router>
        </ConfigProvider>
      </div>
  );
}

export default App;
