import axios from 'axios';
import CONFIG from '../config';

window.counter = 0;

function handleLoading() {
  document.querySelector('body')
      .setAttribute('loading', window.counter > 0 ? 'true' : 'false');
}

export function getAxios() {
  if (!window['custom_axios']) {
    window['custom_axios'] = axios.create({
      baseURL: `${CONFIG.apiEndpoint}`,
    });

    window['custom_axios'].interceptors.request.use(function(config) {
      window.counter++;
      handleLoading();

      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    });

    window['custom_axios'].interceptors.response.use(response => {
      window.counter--;
      handleLoading();

      return response;
    }, error => {
      window.counter--;
      handleLoading();

      if (error.response.status === 401) {
        setTimeout(() => {
          if (window.location.href !== '/auth/init') {
            localStorage.setItem('redirectUrl', window.location.href);
            window.location.href = '/auth/init';
          }
        }, 1000);
      }

      if (error.response.status === 403) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');

        window.location.href = '/auth/init';
        return;
      }

      return Promise.reject(error);
    });
  }

  return window['custom_axios'];
}
