import styles from './_page.module.scss';
import {Button, Form, Input, message} from 'antd';
import {useHistory, useLocation} from 'react-router-dom';
import {useEffect} from 'react';
import useStrapiRest from '../../hooks/useStrapiRest';
import _pageConfig from './_pageConfig';
import {AuthLayout} from '../../components/layouts/AuthLayout';
import {isMobileAndTabletCheck} from '../../helper';

export default function DeviceUpdatePage() {
  const [actions, data] = useStrapiRest(_pageConfig.endpoint);
  const [optionActions, optionData] = useStrapiRest('public-config');
  const location = useLocation();
  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    (async () => {
      await optionActions.getItems();
    })();
  }, []);

  useEffect(() => {
    if (data.detail) {
      form.setFieldsValue({
        ...data.detail,
      });
    }
  }, [JSON.stringify(data?.detail)]);

  useEffect(() => {

    const userName = JSON.parse(localStorage.getItem('user')).email.split('@')[0];
    if (isMobileAndTabletCheck()) {
      form.setFieldValue('name', `${userName} Mobile`);
    } else {
      form.setFieldValue('name', `${userName} Laptop`);
    }
  }, [])

  async function onFinish(values) {
    const currentUserId = JSON.parse(localStorage.getItem('user')).id;

    try {
      await actions.createItem({
        isMobile: isMobileAndTabletCheck(),
        name: values.name,
        macAddress: values.macAddress,
        user: {
          connect: [currentUserId],
        },
      });

      message.success('Registered device successfully!');
      history.push('/dashboard');
    } catch (e) {
      console.log('DEBUG EEE', e);
      try {
        console.log('DEBUG e.response.data.error.message', e.response.data.error.message);
        if (e.response.data.error.message === 'This attribute must be unique') {
          alert('Thiết bị này đã được đăng ký rùi');
        } else {
          alert(2)
          alert(
              'Có lỗi khi đăng ký thiết bị này. Hãy liên hệ Huệ để được hỗ trợ.');
        }
      } catch (e) {
        console.log('DEBUG E', e);
        alert(1)
        alert(
            'Có lỗi khi đăng ký thiết bị này. Hãy liên hệ Huệ để được hỗ trợ.');
      }
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const macAddress = params.get('macAddress');

    if (macAddress === 'undefined') {
      history.push('/');
      alert(
          'Bạn vừa kết nối vào wifi công ty, vui lòng chờ khoảng 30s nữa và thực hiện lại.');
      return;
    }

    if (macAddress) {
      form.setFieldValue('macAddress', macAddress);
    }
  }, [location]);

  return (
      <AuthLayout pageTitle={'Register auto checkin device'}>
        <div className={'pageWrapper'}>
          <div className={'pageContent'}>
            <div className={styles.moduleWrapper}>
              <Form
                  form={form}
                  onFinish={onFinish}
                  colon={false}
                  layout="vertical"
                  style={{
                    width: '100%',
                  }}
                  className={'oneColumns'}
              >
                <Form.Item
                    name={'name'}
                    label={'Device name'}
                    rules={[
                      {required: true},
                      {min: 3},
                      {max: 30},
                    ]}
                >
                  <Input/>
                </Form.Item>
                <Form.Item
                    name={'macAddress'}
                    label={(
                        <div>
                          <span>MAC Address (auto fill)</span>
                          {/*(<Link to={'/devices/mac'}>Click here to auto get your*/}
                          {/*MAC Address</Link>)*/}
                        </div>
                    )}
                    rules={[
                      {required: true},
                      {
                        pattern: /^([0-9a-f]{2}[:-]){5}([0-9a-f]{2})$/,
                        message: 'Please enter a valid MAC address',
                      },
                    ]}
                >
                  <Input readOnly />
                </Form.Item>
                <Form.Item className={'submitRow'}>
                  <Button block type="primary" htmlType="submit">
                    {'Register this device'}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </AuthLayout>
  );
}
