import React, {useEffect, useState} from 'react';
import {Link, NavLink, useHistory, useLocation} from 'react-router-dom';
import styles from './AuthLayout.module.scss';
import {FaHistory, FaUserFriends} from 'react-icons/fa';
import {Dropdown, Space} from 'antd';
import {BiSolidDownArrow} from 'react-icons/bi';
import {AiOutlineAreaChart} from 'react-icons/ai';
import {MdCampaign, MdDevicesOther} from 'react-icons/md';
import useStrapiRest from '../../hooks/useStrapiRest';
import {BsAirplane} from 'react-icons/bs';

const logo = require('../../assets/images/logo-cvg-group.png');

export function AuthLayout(props) {
  const [valid, setValid] = useState(false);
  const [user, setUser] = useState(null);
  const [userActions, userData] = useStrapiRest('users');
  let location = useLocation();
  const history = useHistory();

  useEffect(() => {
    try {
      const temp = localStorage.getItem('user');
      if (temp) {
        setUser(JSON.parse(temp));
      }
    } catch (e) {
      console.log('DEBUG', e);
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      try {
        const tempUser = JSON.parse(localStorage.getItem('user'));

        if (userActions && tempUser && tempUser.id) {
          const latestUser = await userActions.getItem(tempUser.id);
          if (latestUser && latestUser.detail) {
            setUser(latestUser.detail);
            localStorage.setItem('user', JSON.stringify(latestUser.detail));
          }
        }
      } catch (e) {}
    })();
  }, [location]);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setValid(true);
      try {
        setUser(JSON.parse(localStorage.getItem('user')));
      } catch (e) {
      }
    } else {
      localStorage.setItem('redirectUrl', window.location.href);
      window.location.href = '/auth/init';
    }
  }, []);

  if (!valid) {
    return (
        <div>
          <center>Loading data...</center>
        </div>
    );
  }

  const menuLinks = [
    {
      icon: <AiOutlineAreaChart size={20}/>,
      to: '/dashboard',
      name: 'Dashboard',
    },
    {
      icon: <FaHistory size={20}/>,
      to: '/records',
      name: 'Check in histories',
    },
    {
      icon: <MdDevicesOther size={20}/>,
      to: '/devices',
      name: 'Check in devices',
    },
    {
      icon: <FaUserFriends size={20}/>,
      to: '/users',
      name: 'User devices',
    },
    {
      icon: <BsAirplane size={20}/>,
      to: '/leaves',
      name: 'Leave records',
    },
    {
      icon: <MdCampaign size={20}/>,
      to: '/blog',
      name: 'Blog',
    },
  ];

  function handleLogout() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    history.push('/auth/init');
  }

  const onClick = ({key}) => {
    if (key === 'logout') {
      handleLogout();
    } else {
      history.push('/profile');
    }
  };

  const items = [
    {
      label: 'Profile',
      key: 'profile',
    },
    {
      label: 'Logout',
      key: 'logout',
    },
  ];

  return (
      <div className={styles.moduleWrapper}>
        <div className={'wrapper'}>
          <div className="sidebar">
            <div className="logo">
              <Link to={'/'}>My MOHA</Link>
            </div>
            <div className="links">
              {menuLinks.map((item, index) => {
                return (
                    <div key={item.to} className={'link'}>
                      <NavLink href={item.to} to={item.to}
                               activeClassName="selected">
                        {item.icon}
                        <div className="name">
                          {item.name}
                        </div>
                      </NavLink>
                    </div>
                );
              })}
            </div>
          </div>
          <div className="content">
            <div className="pageHeading">
              <div className="left"></div>
              <div className={'pageTitle'}>
                {props.pageTitle || 'Page title'}
              </div>
              <div className="right">
                <Dropdown menu={{items, onClick}} trigger={['click']}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      Hi {user.email}
                      <BiSolidDownArrow size={15}/>
                    </Space>
                  </a>
                </Dropdown>
              </div>
            </div>

            {props.children}
          </div>
        </div>
      </div>
  );
}

